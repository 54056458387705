import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useFilterData } from 'hooks/useFilterData.hook';
import { usePreload } from 'hooks/usePreload.hook';
import * as React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  btn: {
    color: '#3e3e3e',
    backgroundColor: 'transparent',
    display: 'block',
    border: '1px solid gray',
    textAlign: 'center',
    textDecoration: 'none',
    borderRadius: '4px',
    padding: '10px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
  deptBtn: {
    width: '100%',
    padding: '10px 0 !important',
    borderRadius: '4px !important',
  },
}));
export const HomePage = () => {
  const classes = useStyles();
  const filterData = useFilterData();
  const preload = usePreload();

  return (
    <div>
      {preload.messages.map((message) => (
        <div style={{ color: 'black' }}>
          <Typography variant="h4" component="div">
            {message.message_title}
          </Typography>
          <Typography
            variant="p"
            component="p"
            dangerouslySetInnerHTML={{ __html: message.message_body }}
          />
          <hr />
        </div>
      ))}

      <Grid container spacing={2}>
        {filterData.departments.map((dept) => (
          <Grid item xs={12} md={4} lg={3} key={dept.code}>
            <Button
              component={Link}
              variant="outlined"
              color="secondary"
              className={classes.deptBtn}
              to={`/schedule?departments=${dept.code}`}
            >
              {dept.description}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

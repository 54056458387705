import { fetchMessages, fetchTermData } from 'lib/api';
import React, { createContext, useEffect, useState } from 'react';

export const PreloadContext = createContext({
  terms: [],
  defaultTerm: {},
  messages: [],
  loading: true,
  error: null,
});

export const PreloadContextProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [terms, setTerms] = useState();
  const [defaultTerm, setDefaultTerm] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchMessages(), fetchTermData()]).then(
      (results) => {
        const [messageData, termData] = results;

        const currentMessageData = messageData.filter(
          (message) =>
            message.start_date < Date.now() && message.end_date > Date.now()
        );
        const currentTerm = termData.filter((term) => term.active === true);
        setMessages(currentMessageData);
        setTerms(termData);
        setDefaultTerm(currentTerm[0]);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setError(error.message);
        setLoading(false);
      }
    );
  }, []);
  return (
    <PreloadContext.Provider
      value={{
        terms,
        defaultTerm,
        messages,
        loading,
        error,
      }}
    >
      {!loading && children}
    </PreloadContext.Provider>
  );
};

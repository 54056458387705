import {
  Button,
  Container,
  Grid,
  IconButton,
  makeStyles,
  SwipeableDrawer,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MenuIcon from '@material-ui/icons/Menu';
import { Countdown } from 'components/Countdown.component';
import DataTable from 'components/DataTable.component';
import { SemesterButtons } from 'components/SemesterButtons.component';
import { SidebarFilters } from 'components/SidebarFilters.component';
import { useDrawer } from 'hooks/useDrawer.hook';
import { useScheduleData } from 'hooks/useScheduleData.hook';
import { Link, useLocation } from 'react-router-dom';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  semesterBtnContainer: {
    textAlign: 'center',
  },
  drawerBtnContainer: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'right',
    },
  },
  pageTitle: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  drawerCloseBtn: {
    marginTop: theme.spacing(2),
  },
  tableText: {
    fontWeight: 'bold',
  },
  countdown: {
    display: 'block',
    paddingTop: theme.spacing(1),
  },
}));

export const SchedulePage = () => {
  const classes = useStyles();
  const { isOpen, toggle } = useDrawer();
  const scheduleData = useScheduleData();
  const location = useLocation();

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={toggle(false)}
        onOpen={toggle(true)}
      >
        <Container>
          <IconButton
            aria-label="close"
            color="primary"
            variant="outlined"
            className={classes.drawerCloseBtn}
            onClick={toggle(false)}
          >
            <ArrowForwardIcon />
          </IconButton>
          <SidebarFilters />
        </Container>
      </SwipeableDrawer>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            to={`/print${location.search}`}
          >
            Print View{' '}
          </Button>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.semesterBtnContainer}>
          <SemesterButtons />
        </Grid>
        <Grid item xs={12} lg={3} className={classes.drawerBtnContainer}>
          <Button
            onClick={toggle(true)}
            startIcon={<MenuIcon />}
            variant="contained"
            color="primary"
          >
            Filter Results
          </Button>
          <Countdown
            time={scheduleData.nextRefresh}
            loading={scheduleData.loading}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiThemeProvider>
            <DataTable />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
  // };
};

import { Chip, CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useFilters } from 'hooks/useFilters.hook';
import { useScheduleData } from 'hooks/useScheduleData.hook';
import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        responsiveBase: {
          '@media print': {
            zoom: '100%',
          },
        },
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: '18px',
        },
      },
      MUIDataTableToolbar: {
        root: {
          display: 'none',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: '18px',
          backgroundColor: '#f0f0f0',
        },
        sortAction: {
          fontSize: '18px',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          height: '100px',
          '@media print': {
            height: 'auto',
          },
          '&:nth-child(even)': {
            backgroundColor: 'rgba(240, 240, 240, 0.4)',
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
          },
        },
      },
      MUIDataToolbar: {
        root: {
          margin: '0px',
        },
      },
    },
  });

export const DataTable = () => {
  const filters = useFilters();
  const scheduleData = useScheduleData();
  const [expandedRows, setExpandedRows] = useState({ data: [], lookup: {} });

  const sortOrderString = filters.getFilter('sort_by');
  let sortOrderOption = {};
  if (sortOrderString !== null) {
    // course_number.asc
    const values = sortOrderString.split('.');
    const field = values[0];
    const direction = values[1];

    sortOrderOption = {
      courseName: field,
      direction,
    };
  }

  useEffect(() => {
    if (scheduleData.data.isNew) {
      setExpandedRows({ data: [], lookup: {} });
    }
  }, [scheduleData.data.isNew]);

  const TeachingMode = ({ label, color, style, variant, size }) => {
    return (
      <Chip
        color={color}
        label={label}
        style={style}
        variant={variant}
        size={size}
      />
    );
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title=""
        data={scheduleData.data.data}
        columns={[
          {
            name: 'course_section',
            label: 'Crs & Sec',
            options: {
              sort: false,
            },
          },
          {
            name: 'crn',
            label: 'CRN',
            options: {
              sort: false,
            },
          },
          {
            name: 'course_title',
            label: 'Title',
            options: {
              sort: false,
            },
          },
          {
            name: 'credits',
            label: 'Cred',
            options: {
              sort: false,
            },
          },
          {
            name: 'days',
            label: 'Days',
            options: {
              sort: false,
            },
          },
          {
            name: 'times',
            label: 'Time',
            options: {
              sort: false,
            },
          },
          {
            name: 'room',
            label: 'Room',
            options: {
              sort: false,
            },
          },
          {
            name: 'instructor',
            label: 'Instructor',
            options: {
              sort: false,
            },
          },
          {
            name: 'notes',
            label: 'Notes',
            options: {
              sort: false,
            },
          },
          {
            name: 'grad_reqs',
            label: 'Grad. Reqs.',
            options: {
              sort: false,
            },
          },
          {
            name: 'seats_left',
            label: 'Seats Left',
            options: {
              sort: false,
            },
          },
          // {
          //   name: 'notes',
          //   label: 'Teaching Mode',
          //   options: {
          //     filter: true,
          //     sort: false,
          //     customBodyRender: (value) => {
          //       let valueString = value.toString();
          //       if (valueString === undefined) return 'flag';
          //       if (valueString.includes('H'))
          //         return (
          //           <TeachingMode
          //             label={'Hybrid'}
          //             style={{
          //               backgroundColor: '#aae0e1',
          //               color: '#424242',
          //               fontWeight: 'bold',
          //             }}
          //           />
          //         );
          //       if (valueString.includes('O'))
          //         return (
          //           <TeachingMode
          //             label={'Online'}
          //             style={{
          //               backgroundColor: '#f8f878',
          //               color: '#424242',
          //               fontWeight: 'bold',
          //             }}
          //           />
          //         );
          //       if (valueString.includes('I'))
          //         return (
          //           <TeachingMode
          //             label={'Fully In-Person'}
          //             style={{
          //               backgroundColor: '#b3fcd3',
          //               color: '#424242',
          //               fontWeight: 'bold',
          //             }}
          //           />
          //         );
          //       if (valueString.includes('F'))
          //         return (
          //           <TeachingMode
          //             label={'In-Person'}
          //             style={{
          //               backgroundColor: 'pink',
          //               color: '#424242',
          //               fontWeight: 'bold',
          //             }}
          //           />
          //         );
          //     },
          //   },
          //
          // },
          {
            name: 'course_description',
            label: 'Course Description',
            options: {
              display: 'excluded',
            },
          },
          {
            name: 'notes_array',
            label: 'notes array',
            options: {
              display: 'excluded',
            },
          },
          {
            name: 'grad_array',
            label: 'grad array',
            options: {
              display: 'excluded',
            },
          },
        ]}
        options={{
          filter: false,
          textLabels: {
            body: {
              noMatch: scheduleData.loading ? (
                <CircularProgress style={{ color: '#D42121' }} />
              ) : (
                'Sorry, no matching courses found'
              ),
            },
          },
          // filterType: 'dropdown',
          download: 'false',
          print: 'false',
          responsive: 'vertical',
          serverSide: true,
          count: scheduleData.total,
          rowsPerPage: Number(filters.getFilter('limit')),
          page:
            Number(filters.getFilter('offset')) /
            Number(filters.getFilter('limit')),
          rowsPerPageOptions: [50, 100],
          viewColumns: 'false',
          expandableRows: true,
          selectableRows: 'none',
          search: false,
          expandableRowsHeader: false,
          expandableRowsOnClick: true,
          sortOrder: sortOrderOption,
          onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                const limit = filters.getFilter('limit');
                filters.setFilter('offset', limit * tableState.page);
                break;
              case 'changeRowsPerPage':
                filters.setFilters({
                  limit: tableState.rowsPerPage,
                  offset: 0,
                });
                break;
              case 'sort':
                let { name, direction } = tableState.sortOrder;
                let courseName = '';
                if (name === 'course_section') {
                  courseName = 'subject_code';
                } else {
                  courseName = name;
                }
                if (direction === 'none') {
                  filters.setFilter('sort_by', null);
                } else {
                  filters.setFilter('sort_by', `${courseName}.${direction}`);
                }
                break;
              case 'rowExpansionChange':
                setExpandedRows(tableState.expandedRows);
                break;
              case 'propsUpdate':
                if (!scheduleData.data.isNew) {
                  tableState.expandedRows = expandedRows;
                }
                break;
              default:
            }
          },
          isRowExpandable: (dataIndex, expandedRows) => {
            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            if (
              expandedRows.data.length > 4 &&
              expandedRows.data.filter((d) => d.dataIndex === dataIndex)
                .length === 0
            )
              return false;
            return true;
          },
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const h3Styles = {
              margin: '0px',
              color: '#3E3E3E',
              fontSize: '30px',
            };
            const divStyles = {
              lineHeight: '30px',
              fontSize: '18px',
            };
            const h1Styles = {
              margin: '0px',
              color: '#3E3E3E',
              fontSize: '30px',
            };
            let sectionNumber = rowData[0];
            let courseTitle = rowData[2];
            let courseDescription = rowData[11];
            let noteData = rowData[12];
            let gradReqsData = rowData[13];
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <h3 style={h3Styles}>Notes:</h3>
                  {noteData.map((note) => (
                    <div style={divStyles}>
                      <TeachingMode
                        label={note.code}
                        size={'small'}
                        variant={'outlined'}
                        style={{
                          color: '#D42121',
                          fontWeight: 'bold',
                          border: '1px solid #D42121',
                        }}
                      />{' '}
                      - {note.description}
                    </div>
                  ))}{' '}
                  <br />
                  {gradReqsData.length > 0 && (
                    <div>
                      <h3 style={h3Styles}>Grad. Reqs: </h3>
                      {gradReqsData.map((req) => (
                        <div style={divStyles}>
                          <TeachingMode
                            label={req.code}
                            size={'small'}
                            variant={'outlined'}
                            style={{
                              color: '#D42121',
                              fontWeight: 'bold',
                              border: '1px solid #D42121',
                            }}
                          />{' '}
                          - {req.description}
                        </div>
                      ))}{' '}
                      <br />
                    </div>
                  )}
                  <h1 style={h1Styles}>
                    {sectionNumber} - {courseTitle}{' '}
                  </h1>{' '}
                  <div
                    style={{ fontSize: '18px', color: '#3E3E3E' }}
                    dangerouslySetInnerHTML={{ __html: courseDescription }}
                  />
                </TableCell>
              </TableRow>
            );
          },
          //   onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
          // console.log(curExpanded, allExpanded, rowsExpanded),
        }}
      />
    </MuiThemeProvider>
  );
};

export default DataTable;

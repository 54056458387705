import { Button, Grid } from '@material-ui/core';
import { useFilters } from 'hooks/useFilters.hook';
import React, { useState, useEffect } from 'react';
import { fetchCourseData } from 'lib/api';
import { rowFormatter } from 'lib/helper';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

export const PrintPage = () => {
  const filters = useFilters();
  const [data, setData] = useState([]);

  const browserBack = () => {
    let sUsrAg = navigator.userAgent;

    if (sUsrAg.indexOf('Firefox') > -1) {
      window.history.go(-2);
    } else if (sUsrAg.indexOf('Edge') > -1) {
      window.history.go(-1);
    } else if (sUsrAg.indexOf('Chrome') > -1) {
      window.history.go(-1);
    } else if (sUsrAg.indexOf('Safari') > -1) {
      window.history.go(-2);
    } else {
      window.history.go(-1);
    }
  };

  const fetch = () => {
    const params = queryString.parse(filters.search, {
      arrayFormat: 'comma',
    });
    params.limit = 1000;
    params.offset = 0;
    const search = queryString.stringify(params, {
      arrayFormat: 'comma',
    });
    fetchCourseData(search).then((result) => {
      const rowData = result.map(rowFormatter);
      setData(rowData);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      fetch();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tdStyle = {
    paddingBottom: '5px',
    fontSize: '12px',
    paddingRight: '10px',
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={0}>
          <Button
            id="go-back"
            component={Link}
            variant="contained"
            color="primary"
            onClick={() => browserBack()}
          >
            Go Back{' '}
          </Button>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Button
            id="print-button"
            variant="contained"
            color="primary"
            onClick={() => window.print()}
          >
            Print{' '}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <table style={{ textAlign: 'left', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>Crs & Sec</th>
                <th>CRN</th>
                <th>Title</th>
                <th>Cred</th>
                <th>Days</th>
                <th>Times</th>
                <th>Room</th>
                <th>Instructor</th>
                <th>Notes</th>
                <th>Grad. Reqs.</th>
                <th>Seats Left</th>
              </tr>
            </thead>
            {data.map((row) => (
              <tr>
                <td
                  style={{
                    paddingBottom: '5px',
                    fontSize: '12px',
                    width: '75px',
                  }}
                >
                  {row.course_section}
                </td>
                <td style={tdStyle}>{row.crn}</td>
                <td style={tdStyle}>{row.course_title}</td>
                <td style={tdStyle}>{row.credits}</td>
                <td style={tdStyle}>{row.days}</td>
                <td
                  style={{
                    paddingBottom: '5px',
                    fontSize: '12px',
                    width: '125px',
                  }}
                >
                  {row.times}
                </td>
                <td style={tdStyle}>{row.room}</td>
                <td style={tdStyle}>{row.instructor}</td>
                <td style={tdStyle}>{row.notes}</td>
                <td style={tdStyle}>{row.grad_reqs}</td>
                <td style={tdStyle}>{row.seats_left}</td>
              </tr>
            ))}
          </table>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

import { Breadcrumbs, Link, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { useLocation } from 'react-router';
import { useFilterData } from 'hooks/useFilterData.hook';
import { useFilters } from 'hooks/useFilters.hook';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  crumbContainer: {
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      color: 'black',
    },
  },
  crumbs: {
    [theme.breakpoints.down('lg')]: {
      display: 'inline-block',
    },
  },
}));

const links = {
  color: 'black',
  textDecoration: 'none',
};
export const RouterBreadcrumb = () => {
  const classes = useStyles();
  const location = useLocation();
  const filters = useFilters();
  const filterData = useFilterData();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div className={classes.crumbContainer}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.crumbs}>
        <Link style={{ color: 'black' }} component={RouterLink} to="/">
          Departments
        </Link>
        {pathnames.map((value, idx) => {
          const last = idx === pathnames.length - 1;
          const to = `/${pathnames.slice(0, idx + 1).join('/')}`;
          //const text = value.charAt(0).toUpperCase() + value.substring(1);
          const key = filters.getFilter('departments');
          let department = filterData.departments.map((department) => {
            if (key === department.code) {
              return department.description;
            }
            return null;
          });
          if (key === null) {
            department = 'All';
          }
          return last ? (
            <Typography style={links} key={to}>
              {department}
            </Typography>
          ) : (
            <Link style={links} component={RouterLink} to="/" key={to}>
              {department}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#D42121',
    },
    secondary: {
      main: '#0f4354',
    },
    text: {
      primary: 'rgba(49,49,49,0.87)',
    },
  },
  typography: {
    fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
  },
});

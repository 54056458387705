import { useFilters } from 'hooks/useFilters.hook';
import { usePreload } from 'hooks/usePreload.hook';
import { fetchTermFilters } from 'lib/api';
import { timeConvert } from 'lib/helper';
import { createContext, useEffect, useState } from 'react';

export const FilterDataContext = createContext({
  departments: [],
  times: [],
  gradReqs: [],
  days: [],
  terms: [],
  defaultTerm: {},
  error: {},
  loading: true,
});

let timer;

const departmentNameTransform = {
  ARB: 'Arab Studies',
  CIS: 'Center for Interdisciplinary Studies',
  CHI: 'Chinese Studies',
  WRI: 'Writing Program',
  COM: 'Communication Studies',
  FRE: 'French and Francophone Studies',
  GSS: 'Gender and Sexuality Studies',
  GER: 'German Studies',
  LIT: 'Global Literary Theory', 
  REL: 'Religious Studies',
  RUS: 'Russian Studies',
  SPA: 'Hispanic Studies',
  XPL: 'High Impact Exp. Learning',
};

export const FilterDataContextProvider = ({ children }) => {
  const filters = useFilters();
  const { terms, defaultTerm } = usePreload();
  const [departments, setDepartments] = useState([]);
  const [times, setTimes] = useState([]);
  const [gradReqs, setGradReqs] = useState([]);
  const [days, setDays] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);

    try {
      // fetch filter data for term using either the default, or the current
      // query param value
      const filterData = await fetchTermFilters(filters.getFilter('term_code'));

      const departmentData = filterData.departments.map((department) => {
        department.description =
          departmentNameTransform[department.code] || department.description;
        return department;
      });
      departmentData.sort((a, b) => {
        let varDepartmentA = a.description.toUpperCase();
        let varDepartmentB = b.description.toUpperCase();
        return varDepartmentA < varDepartmentB
          ? -1
          : varDepartmentA > varDepartmentB
          ? 1
          : 0;
      });
      setDepartments(departmentData);
      setTimes(convertTimes(filterData.times));
      setGradReqs(filterData.grad_requirements);
      setDays(convertDays(filterData.days));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  /**
   *
   * "days": [
      [],
      [ 1, 3, 5 ],
      [ 1, 3 ],
  ]
   */
  const convertDays = (apiDays) => {
    const dayLookup = ['S', 'M', 'T', 'W', 'R', 'F', 'S'];

    return apiDays
      .map((meetingDays) => {
        if (meetingDays.length === 0) {
          return null;
          // return {
          //   value: 'none',
          //   description: 'Select a Meeting Day',
          // };
        }

        const description = meetingDays
          .map((d) => {
            return dayLookup[d];
          })
          .join(' ');

        return {
          value: meetingDays.join(','),
          description,
        };
      })
      .filter((meeting) => meeting !== null);
  };

  // "-"
  // "0930-1030"
  // [{value: "0930-1030", description: "9:30 - 10:30 am"}]
  const convertTimes = (apiTimes) => {
    return apiTimes.map((time) => {
      if (time === '-') {
        return { value: 'none', description: 'Select Meeting Time' };
      }

      const [start, finish] = time.split('-');

      const [startTime] = timeConvert(start);
      const [endTime, endAmPm] = timeConvert(finish);

      return {
        value: time,
        description: `${startTime} - ${endTime} ${endAmPm}`,
      };
    });
  };

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fetchData();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.term]);

  return (
    <FilterDataContext.Provider
      value={{
        departments,
        times,
        gradReqs,
        days,
        error,
        loading,
        terms,
        defaultTerm,
      }}
    >
      {children}
    </FilterDataContext.Provider>
  );
};

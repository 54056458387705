import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { SemesterButtons } from 'components/SemesterButtons.component';
import { useFilterData } from 'hooks/useFilterData.hook';
import { useFilters } from 'hooks/useFilters.hook';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  formControl: {
    margin: `${theme.spacing(1)} 0`,
    minWidth: 120,
    maxWidth: 440,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  formInput: {
    height: 'auto !important',
  },
  inputContainer: {
    paddingTop: `${theme.spacing(0)}px`,
    paddingBottom: `${theme.spacing(2)}px`,
  },
  semesterLabel: {
    fontSize: '12px',
    marginBottom: theme.spacing(1),
  },
  resetFilterButton: {
    padding: '15px 0',
  },
  fullCourse: {
    color: theme.secondary,
  },
}));

const ITEM_HEIGHT = 90;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DavidsonSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'rgb(179 10 10 / 100%)',
    '&:hover': {
      backgroundColor: 'rgb(179 10 10 / 17%)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: 'rgb(179 10 10 / 77%)',
  },
}));

export const SidebarFilters = () => {
  const classes = useStyles();
  const filters = useFilters();
  const filterData = useFilterData();

  const meetingTimes = filterData.times.reduce((acc, time) => {
    acc[time.value] = time;

    return acc;
  }, {});

  const normalizedMeetingTime = () => {
    const meetTime = filters.getFilter('time');
    if (!meetTime) {
      return [];
    }

    return Array.isArray(meetTime) ? meetTime : [meetTime];
  };

  const isChecked = () => {
    const full = filters.getFilter('min_enrollment_remaining');

    if (full === '1') {
      return true;
    }
    if (full === '-100') {
      return false;
    }
  };

  return (
    <div>
      <div className={classes.inputContainer}>
        {/* <FilterLabel>Semester</FilterLabel> */}
        <InputLabel
          id="select-grad-requirements-label"
          className={classes.semesterLabel}
        >
          Semester
        </InputLabel>
        <SemesterButtons />
      </div>
      <div className={classes.inputContainer}>
        {/* <FilterLabel>Graduation Requirements</FilterLabel> */}
        <FormControl className={classes.formControl} fullWidth={true}>
          <InputLabel id="select-grad-requirements-label">
            Graduation Requirements
          </InputLabel>
          <Select
            labelId="select-grad-requirements-label"
            // value={filters.getFilter('grad_requirements') || 'none'}
            value={filters.getFilter('grad_requirements')}
            id="graduation-requirements"
            input={<Input id="graduation-requirements-select" />}
            onChange={(event) => {
              filters.setFilter('grad_requirements', event.target.value);
            }}
          >
            {/* <MenuItem value="none">Select a graduation requirement</MenuItem> */}
            {filterData.gradReqs.map((req) => (
              <MenuItem value={req.code} key={req.code}>
                {req.description} ({req.code})
              </MenuItem>
            ))}
          </Select>
          <div>
            <Link
              className={classes.resetFilterButton}
              component="button"
              onClick={() => {
                filters.setFilter('grad_requirements', null);
              }}
            >
              Clear Graduation Requirements
            </Link>
          </div>
        </FormControl>
      </div>
      <div className={classes.inputContainer}>
        {/* <FilterLabel>Meeting Days</FilterLabel> */}
        <FormControl className={classes.formControl} fullWidth={true}>
          <InputLabel id="select-meet-days-label">Meet Days</InputLabel>
          <Select
            // value={filters.getFilter('days') || 'none'}
            value={filters.getFilter('days')}
            labelId="select-meet-days-label"
            id="meet-days"
            input={<Input id="meet-days-select" />}
            onChange={(event) => {
              filters.setFilter('days', event.target.value);
            }}
          >
            {filterData.days.map((day) => (
              <MenuItem value={day.value} key={day.value}>
                {day.description}
              </MenuItem>
            ))}
          </Select>
          <div>
            <Link
              className={classes.resetFilterButton}
              component="button"
              onClick={() => {
                filters.setFilter('days', null);
              }}
            >
              Clear Meet Days
            </Link>
          </div>
        </FormControl>
      </div>
      <div className={classes.inputContainer}>
        <FormControl className={classes.formControl} fullWidth={true}>
          <InputLabel id="select-meet-times-label">Meeting Times</InputLabel>
          <Select
            labelId="select-meet-times-label"
            id="meet-times"
            multiple
            value={normalizedMeetingTime()}
            className={classes.formInput}
            input={<Input id="meet-times-select" />}
            MenuProps={MenuProps}
            onChange={(event) => {
              const val = Array.isArray(event.target.value)
                ? event.target.value
                : [event.target.value];

              filters.setFilter('time', val);
            }}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={meetingTimes[value].description}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
          >
            {filterData.times.map((meet) => (
              <MenuItem key={meet.value} value={meet.value}>
                {meet.description}
              </MenuItem>
            ))}
          </Select>

          <div>
            <Link
              className={classes.resetFilterButton}
              component="button"
              onClick={() => {
                filters.setFilter('time', null);
              }}
            >
              Clear Meet Times
            </Link>
          </div>
        </FormControl>
      </div>
      <div className={classes.inputContainer}>
        {/* <FilterLabel>Choose Your Field of Study</FilterLabel> */}
        <FormControl className={classes.formControl} fullWidth={true}>
          <InputLabel id="select-field-of-study-label">
            Choose Your Field of Study
          </InputLabel>
          <Select
            labelId="select-field-of-study-label"
            id="field-of-study"
            value={filters.getFilter('departments')}
            className={classes.formInput}
            input={<Input id="field-of-study-select" />}
            onChange={(event) => {
              filters.setFilter('departments', event.target.value);
            }}
          >
            {filterData.departments.map((dept) => (
              <MenuItem value={dept.code} key={dept.code}>
                {dept.description}
              </MenuItem>
            ))}
          </Select>
          <div>
            <Link
              className={classes.resetFilterButton}
              component="button"
              onClick={() => {
                filters.setFilter('departments', null);
              }}
            >
              Clear Field of Study
            </Link>
          </div>
        </FormControl>
      </div>
      <div className={classes.inputContainer}>
        {/* <FilterLabel>Choose Your Field of Study</FilterLabel> */}
        <InputLabel id="full-course-label">Remove Full Courses:</InputLabel>
        <DavidsonSwitch
          labelId="toggle-full-course-label"
          id="full-course"
          checked={isChecked()}
          onChange={(event) => {
            const val = event.target.checked ? 1 : -100;
            filters.setFilter('min_enrollment_remaining', val);
          }}
        />
      </div>
    </div>
  );
};

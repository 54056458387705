import { Layout } from 'components/Layout.component';
import { DrawerContextProvider } from 'context/Drawer.context';
import { FilterContextProvider } from 'context/Filter.context';
import { FilterDataContextProvider } from 'context/FilterData.context';
import { PreloadContextProvider } from 'context/Preload.context';
import { ScheduleDataContextProvider } from 'context/ScheduleData.context';
import { printWelcome } from 'lib/helper';
import { HomePage } from 'pages/HomePage';
import { SchedulePage } from 'pages/SchedulePage';
import { PrintPage } from 'pages/PrintPage';
import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

function App({ children }) {
  useEffect(() => {
    printWelcome();
  }, []);
  return (
    <HashRouter>
      <DrawerContextProvider>
        <PreloadContextProvider>
          <FilterContextProvider>
            <FilterDataContextProvider>
              <Switch>
                <Route path="/print">
                  <PrintPage />
                </Route>
                <Route path="/">
                  <Layout>
                    <Switch>
                      <Route path="/schedule">
                        <ScheduleDataContextProvider>
                          <SchedulePage />
                        </ScheduleDataContextProvider>
                      </Route>
                      <Route path="/">
                        <HomePage />
                      </Route>
                    </Switch>
                  </Layout>
                </Route>
              </Switch>
            </FilterDataContextProvider>
          </FilterContextProvider>
        </PreloadContextProvider>
      </DrawerContextProvider>
    </HashRouter>
  );
}

export default App;

/**
 *
 * @returns {TermsApiResult}
 */
export const fetchTermData = async () => {
  const response = await fetch(
    'https://api.davidson.edu/micro/public/v2/course-schedule/terms'
  );
  if (response.status !== 200) {
    throw new Error('unable to fetch term data');
  }
  const result = await response.json();
  return result;
};

/**
 * Fetch the filter data for the given term
 *
 * @param {String} term
 * @returns {FiltersApiResult}
 */
export const fetchTermFilters = async (term) => {
  const response = await fetch(
    `https://api.davidson.edu/micro/public/v2/course-schedule/filters/${term}`
  );

  if (response.status !== 200) {
    throw new Error('unable to fetch term data');
  }

  const result = await response.json();

  return result;
};

/**
 * Fetch course data
 * @param {string} filters
 * @returns {CoursesApiResult}
 */
export const fetchCourseData = async (filters) => {
  const response = await fetch(
    `https://api.davidson.edu/api/public/v2/courses?${filters}`
  );

  if (response.status !== 200) {
    throw new Error('unable to fetch term data');
  }

  const result = await response.json();

  return result;
};

export const fetchCourseHeaders = async (filters) => {
  const response = await fetch(
    `https://api.davidson.edu/api/public/v2/courses?${filters}`
  );

  // fetch all the response headers.
  let headers = [...response.headers];

  // create an object to hold the response header we desire.
  let recordCount = { header: '', count: 0 };

  // comb through all the reseponse headers and extract the record count header.
  // eslint-disable-next-line
  let headerArray = headers.map((header) => {
    if (header[0] === 'x-record-count') {
      recordCount.header = header[0];
      recordCount.count = header[1];
      return recordCount;
    } else {
      return null;
    }
  });

  if (response.status !== 200) {
    throw new Error('unable to fetch term data');
  }

  const result = await recordCount;

  return result;
};

/**
 * Fetch messages data
 * @returns {MessagesApiResult}
 */
export const fetchMessages = async () => {
  const response = await fetch(
    'https://api.davidson.edu/micro/public/v2/course-schedule/messages'
  );

  if (response.status !== 200) {
    throw new Error('unable to fetch term data');
  }

  const result = await response.json();

  return result;
};

//////////////////////////////////////////////////////////
// Return type JsDocs
//////////////////////////////////////////////////////////
/**
 * @typedef {Object} TermsApiResult
 * @property {object[]} terms_to_display
 * @property {string} terms_to_display[].term_code
 * @property {string} terms_to_display[].term_description
 * @property {object} active_term
 * @property {string} active_term.term_code
 * @property {string} active_term.term_description
 */

/**
 * @typedef {Object} CoursesQueryFilterParams
 * @property {number} grad_requirements
 * @property {number} limit
 * @property {string} departments
 * @property {number} offset
 * @property {string} sort_by
 * @property {string} term_code
 * @property {string[]} times
 */

/**
 * @typedef {Object} CoursesApiResult
 * @property {string} _url
 * @property {?*} _previousUrl
 * @property {string} _nextUrl
 * @property {number} _recordCount
 * @property {object} _filters
 * @property {?*} _filters.term_code
 * @property {?*} _filters.subject_code
 * @property {?*} _filters.course_number
 * @property {?*} _filters.course_title
 * @property {?*} _filters.notes
 * @property {?*} _filters.grad_requirements
 * @property {?*} _filters.majors
 * @property {?*} _filters.days
 * @property {?*} _filters.times
 * @property {object} _sort_by
 * @property {object} _sort_by._id
 * @property {string} _sort_by._id.asc
 * @property {string} _sort_by._id.desc
 * @property {object} _sort_by.id
 * @property {string} _sort_by.id.asc
 * @property {string} _sort_by.id.desc
 * @property {object} _sort_by.subject_code
 * @property {string} _sort_by.subject_code.asc
 * @property {string} _sort_by.subject_code.desc
 * @property {object} _sort_by.course_number
 * @property {string} _sort_by.course_number.asc
 * @property {string} _sort_by.course_number.desc
 * @property {object} _sort_by.crn
 * @property {string} _sort_by.crn.asc
 * @property {string} _sort_by.crn.desc
 * @property {object} _sort_by.course_title
 * @property {string} _sort_by.course_title.asc
 * @property {string} _sort_by.course_title.desc
 * @property {object} _sort_by.section
 * @property {string} _sort_by.section.asc
 * @property {string} _sort_by.section.desc
 * @property {number} _limit
 * @property {number} _offset
 * @property {object[]} records
 * @property {number} records[].course_number
 * @property {number} records[].crn
 * @property {object} records[].department
 * @property {string} records[].department.code
 * @property {string} records[].department.description
 * @property {object[]} records[].notes
 * @property {string} records[].notes[].code
 * @property {string} records[].notes[].desc
 * @property {number} records[].credits
 * @property {object} records[].enrollment
 * @property {number} records[].enrollment.current
 * @property {number} records[].enrollment.max
 * @property {number} records[].enrollment.remaining
 * @property {string} records[].term_code
 * @property {object} records[].term
 * @property {string} records[].term.code
 * @property {string} records[].term.description
 * @property {object[]} records[].majors
 * @property {string} records[].majors[].code
 * @property {string} records[].majors[].description
 * @property {object[]} records[].grad_requirements
 * @property {string} records[].grad_requirements[].code
 * @property {string} records[].grad_requirements[].description
 * @property {*[]} records[].cross_postings
 * @property {*[]} records[].cross_listings
 * @property {string} records[].section
 * @property {string} records[].course_oid
 * @property {*[]} records[].reg_for
 * @property {string} records[].cat_oid
 * @property {string} records[].subject_code
 * @property {string} records[].course_title
 * @property {object[]} records[].meetings
 * @property {string} records[].meetings[].type
 * @property {number[]} records[].meetings[].days
 * @property {string} records[].meetings[].start_time
 * @property {string} records[].meetings[].end_time
 * @property {?*} records[].meetings[].building_code
 * @property {?*} records[].meetings[].room
 * @property {object[]} records[].instructors
 * @property {string} records[].instructors[].first_name
 * @property {string} records[].instructors[].last_name
 * @property {string} records[].instructors[].campus_id
 * @property {string} records[].course_description
 * @property {number} records[].id
 * @property {string} records[].course_title_normalized
 * @property {number} records[].course_title_sort_index
 */

/**
 * @typedef {Object} FiltersApiResult
 * @property {object[]} departments
 * @property {string} departments[].code
 * @property {string} departments[].description
 * @property {object[]} grad_requirements
 * @property {string} grad_requirements[].code
 * @property {string} grad_requirements[].description
 * @property {array[]} days
 * @property {string[]} times
 */

/**
 * @typedef {MessagesResult[]} MessagesApiResult
/**
 * @typedef {Object} MessagesResult
 * @property {string} id
 * @property {string} message_title
 * @property {string} message_body
 * @property {number} start_date
 * @property {number} end_date
 * @property {string} submitted_by
 * @property {number} submitted_at
 * @property {number} updated_at
 */

import { styled } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useFilterData } from 'hooks/useFilterData.hook';
import { useFilters } from 'hooks/useFilters.hook';
import * as React from 'react';

const SemesterToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.grey.A100,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  '& .Mui-selected, & .Mui-selected:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderTop: theme.palette.secondary.main,
    borderBottom: theme.palette.secondary.main,
  },
  '&:not(:first-of-type)': {
    borderRadius: theme.shape.borderRadius,
  },
  '&:first-of-type': {
    borderRadius: theme.shape.borderRadius,
  },
}));

export const SemesterButtons = () => {
  const filters = useFilters();
  const filterData = useFilterData();

  const handleChange = React.useCallback(
    (event, value) => {
      filters.setFilter('term_code', value);
    },
    [filters]
  );

  return (
    <div>
      <SemesterToggleButtonGroup
        value={filters.getFilter('term_code')}
        exclusive
        onChange={handleChange}
      >
        {filterData.terms.map((term) => (
          <ToggleButton value={term.term_code} key={term.term_code}>
            {term.term_description}
          </ToggleButton>
        ))}
      </SemesterToggleButtonGroup>
    </div>
  );
};

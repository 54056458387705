import React, { createContext, useCallback } from 'react';

export const DrawerContext = createContext({
  isOpen: false,
  toggle: (open) => () => {},
});

export const DrawerContextProvider = ({ children }) => {
  const [openState, setOpenState] = React.useState(false);

  const toggleDrawer = useCallback(
    (open) => (event) => {
      if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }

      setOpenState(open);
    },
    [setOpenState]
  );

  return (
    <DrawerContext.Provider
      value={{
        isOpen: openState,
        toggle: toggleDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

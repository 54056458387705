import { DrawerContext } from 'context/Drawer.context';
import { useContext } from 'react';

export const useDrawer = () => {
  const { isOpen, toggle } = useContext(DrawerContext);

  return {
    isOpen,
    toggle,
  };
};

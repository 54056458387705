import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
//import { DavidsonLogo } from './DavidsonLogo.component';
import { RouterBreadcrumb } from './RouterBreadcrumb.component';
//import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
  },
  header: {
    paddingBottom: '30px',
  },
  logo: {
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },
}));

export const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} className={classes.logo}>
            <Link component={RouterLink} to="/">
              <DavidsonLogo width={250} />
            </Link>
          </Grid> */}
          <Grid item xs={12}>
            <RouterBreadcrumb />
          </Grid>
        </Grid>
      </div>
      {children}
    </div>
  );
};

import { usePreload } from 'hooks/usePreload.hook';
import queryString from 'query-string';
import { createContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// list of filters that will trigger offset to be set to 0
const pageResetFilters = [
  'departments',
  'limit',
  'term_code',
  'grad_requirements',
  'days',
  'time',
];

export const FilterContext = createContext({
  setFilter: (filterName, filterVal) => {},
  setFilters: (filters) => {},
  getFilter: (filterName) => {},
  clearFilters: () => {},
  search: '',
  term: '',
});

const defaults = {
  limit: 50,
  offset: 0,
};

export const FilterContextProvider = ({ children }) => {
  const { search, pathname } = useLocation();
  const { defaultTerm } = usePreload();
  const history = useHistory();
  const params = {
    ...defaults,
    term_code: defaultTerm.term_code,
    ...queryString.parse(search, {
      arrayFormat: 'comma',
    }),
  };

  const setFilter = (filterName, filterVal) => {
    let newFilters = {
      ...params,
      [filterName]: filterVal,
    };

    if (pageResetFilters.includes(filterName)) {
      newFilters.offset = 0;
    }

    if (filterVal === null || filterVal === 'none') {
      delete newFilters[filterName];
    }

    history.push({
      pathname,
      search: queryString.stringify(newFilters, {
        arrayFormat: 'comma',
      }),
    });
  };

  const setFilters = (filters) => {
    let newFilters = {
      ...params,
      ...filters,
    };

    if (Object.keys(filters).some((key) => pageResetFilters.includes(key))) {
      newFilters.offset = 0;
    }

    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key] === null || newFilters[key] === 'none') {
        delete newFilters[key];
      }
    });

    history.push({
      pathname,
      search: queryString.stringify(newFilters, {
        arrayFormat: 'comma',
      }),
    });
  };

  const getFilter = (filterName) => params[filterName] || null;

  const clearFilters = () => {
    history.push({
      pathname,
      search: '',
    });
  };

  return (
    <FilterContext.Provider
      value={{
        setFilter,
        setFilters,
        getFilter,
        clearFilters,
        search: queryString.stringify(params, { arrayFormat: 'comma' }),
        term: getFilter('term_code'),
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

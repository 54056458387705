import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  countdown: {
    display: 'block',
    paddingTop: theme.spacing(1),
  },
}));

let interval;
export const Countdown = ({ time, loading }) => {
  const classes = useStyles();
  const [diff, setDiff] = useState(0);

  const checkTime = () => {
    const now = Math.floor(Date.now() / 1000);
    setDiff(time - now);
  };

  useEffect(() => {
    interval = setInterval(checkTime, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  let displayValue = 'Loading';

  if (diff > 0) {
    displayValue = `Next Refresh: ${diff}s`;
  } else if (!loading && diff < 0) {
    displayValue = `Next Refresh: ${process.env.REACT_APP_RELOAD_DELAY}s`;
  }

  return <div className={classes.countdown}>{displayValue}</div>;
};

import { useFilters } from 'hooks/useFilters.hook';
import { fetchCourseData, fetchCourseHeaders } from 'lib/api';
import { rowFormatter } from 'lib/helper';
import { createContext, useCallback, useEffect, useState } from 'react';

export const ScheduleDataContext = createContext({
  loading: true,
  data: { isNew: true, data: [] },
  total: 0,
  error: null,
  nextRefresh: null,
});

let timer;
let interval;

// this would not work in a state variable
export const ScheduleDataContextProvider = ({ children }) => {
  const filters = useFilters();
  const [data, setData] = useState({ isNew: false, data: [] });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextRefresh, setNextRefresh] = useState(null);

  /**
   * reset the refresh time stamp
   */
  const resetRefreshTime = () => {
    setNextRefresh(
      Math.floor(Date.now() / 1000) + Number(process.env.REACT_APP_RELOAD_DELAY)
    );
  };

  /**
   * Check if the data should be refreshed
   * @returns {boolean}
   */
  const isRefreshExpired = useCallback(() => {
    if (!nextRefresh) return false;

    const now = Math.floor(Date.now() / 1000);

    return nextRefresh - now <= 0;
  }, [nextRefresh]);

  /**
   * Fetch the course data using the current search params
   */
  const fetchData = useCallback(
    (isNew = true) => {
      setLoading(true);

      fetchCourseData(filters.search).then(
        (result) => {
          resetRefreshTime();
          const rowData = result.map(rowFormatter);
          setData({ isNew, data: rowData });
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );

      fetchCourseHeaders(filters.search).then(
        (result) => {
          setTotal(result.count);
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
    },
    [filters.search]
  );

  const checkForUpdate = useCallback(() => {
    if (isRefreshExpired()) {
      fetchData(false);
    }
  }, [fetchData, isRefreshExpired]);

  useEffect(() => {
    // debounce query
    timer = setTimeout(() => {
      fetchData(true);
    }, 300);

    return () => {
      // clean up
      clearTimeout(timer);
      setNextRefresh(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.search]);

  useEffect(() => {
    // check the time ever second to see if the data needs to be refreshed
    interval = setInterval(() => {
      checkForUpdate();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [checkForUpdate, nextRefresh]);

  return (
    <ScheduleDataContext.Provider
      value={{
        loading,
        data,
        total,
        error,
        nextRefresh,
      }}
    >
      {children}
    </ScheduleDataContext.Provider>
  );
};

// 0805-0920
// 0805
export function timeConvert(time) {
  if (time === null) {
    return '';
  }

  let hour = Number(time.slice(0, 2));
  const minutes = time.slice(2, 4);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour > 12 ? hour - 12 : hour;

  return [`${hour}:${minutes}`, ampm];

  // // Check correct time format and split into components
  // time = time.toString().match(/^([01]\d|2[0-3])([0-5]\d)$/) || [time];

  // if (time.length > 1) { // If time format correct
  //     time = time.slice(1);  // Remove full string match value
  //     time[3] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
  //     time[0] = +time[0] % 12 || 12; // Adjust hours
  // }
  // return time.join(''); // return adjusted time or original string
}

export function createString(arrayValue) {
  let output = '';
  for (let i = 0; i < arrayValue.length; i++) {
    output += arrayValue[i] + ' ';
  }
  let outputTrim = output.trim();
  output = outputTrim.replaceAll(' ', ', ');
  return output;
}

/**
 *
 * "days": [
    [],
    [ 1, 3, 5 ],
    [ 1, 3 ],
]
 */
export function convertDays(apiDays) {
  return apiDays.map((day) => {
    if (day.length === 0) {
      return {
        value: 'none',
        description: 'Select a Meeting Day',
      };
    }
    return convertDay(day);
  });
}

export function convertDay(dayArray) {
  if (dayArray === undefined) {
    return {
      value: '',
      description: 'flag',
    };
  }
  const dayLookup = ['S', 'M', 'T', 'W', 'R', 'F', 'S'];

  const description = dayArray.map((d) => {
    const allDays = d.days;
    // if (allDays === undefined) {
    //   return;
    // }
    let check = allDays
      .map((day) => {
        return dayLookup[day];
      })
      .join('');
    return check;
  });

  if (description.includes('')) {
    description.push('TBA');
  }

  return description;
}

export const convertTimes = (meetingData) => {
  return meetingData.map((time) => {
    const [startTime] = timeConvert(time.start_time);
    const [endTime, endAmPm] = timeConvert(time.end_time);

    if (startTime === undefined && endTime === undefined) {
      return 'TBA';
    }

    return `${startTime} - ${endTime} ${endAmPm}`;
  });
};

export const rooms = (roomData) => {
  return roomData.map((room) => {
    let roomNumber = room.room;
    let building = room.building;
    if (roomNumber === null && building === null) {
      return 'TBA';
    }

    return `${building.code} ${roomNumber}`;
  });
};

export function instructors(instructorArray) {
  let check = instructorArray
    .map((instructor) => {
      let [firstName] = instructor.first_name;
      let lastName = instructor.last_name;
      let firstNameInitial = firstName.charAt(0);

      return `${lastName} ${firstNameInitial}`;
    })
    .join(' & ');

  return check;
}

export const leadingZero = (crsNumber) => {
  if (crsNumber.toString().length === 2) {
    let number = crsNumber;
    return '0' + number;
  }
  return crsNumber;
};

export function comma(array) {
  let code = array
    .map((note) => {
      let oneNote = note.code;

      return `${oneNote}`;
    })
    .join(', ');

  return code;
}

export const rowFormatter = (row) => {
  return {
    course_section: `${row.subject.code}-${leadingZero(row.course_number)}-${
      row.section
    }`,
    crn: row.crn,
    course_title: row.course_title,
    credits: row.credits,
    days: convertDay(row.meetings)
      .reverse()
      .map((day) => <p style={{ margin: 'auto' }}>{day}</p>),
    times: convertTimes(row.meetings)
      .reverse()
      .map((time) => <p style={{ margin: 'auto' }}>{time}</p>),
    room: rooms(row.meetings)
      .reverse()
      .map((meeting) => <p style={{ margin: 'auto' }}>{meeting}</p>),
    instructor: instructors(row.instructors),
    notes: comma(row.notes),
    notes_array: row.notes,
    grad_reqs: comma(row.grad_requirements),
    grad_array: row.grad_requirements,
    seats_left: `${row.enrollment.remaining}/${row.enrollment.max}`,
    course_description: row.course_description,

    raw: row,
  };
};

export function printWelcome() {
  console.log(`
8888888b.                    d8b      888                                  88888888888  .d8888b.     8888888
888  "Y88b                   Y8P      888                                      888     d88P  "88b      888
888    888                            888                                      888     Y88b. d88P      888
888    888  8888b.  888  888 888  .d88888 .d8888b   .d88b.  88888b.            888      "Y8888P"       888
888    888     "88b 888  888 888 d88" 888 88K      d88""88b 888 "88b           888     .d88P88K.d88P   888
888    888 .d888888 Y88  88P 888 888  888 "Y8888b. 888  888 888  888           888     888"  Y888P"    888
888  .d88P 888  888  Y8bd8P  888 Y88b 888      X88 Y88..88P 888  888           888     Y88b .d8888b    888
8888888P"  "Y888888   Y88P   888  "Y88888  88888P'  "Y88P"  888  888           888      "Y8888P" Y88b888888
    `);

  console.log(
    '01000001 01101100 01100101 01101110 01100100 01100001  01001100 01110101 01111000  01010101 01100010 01101001  01001111 01110010 01110100 01100001  01001100 01101001 01100010 01100101 01110010 01110100 01100001 01110011 '
  );
}
